// Sidebar.js
import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { GoPlusCircle } from "react-icons/go";

import { MdQuestionAnswer, MdHistory, MdLogin, MdAccountCircle, MdLogout, MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight } from 'react-icons/md'; // Importing icons
import Dropdown from 'react-bootstrap/Dropdown';
import SplitButton from 'react-bootstrap/SplitButton';
import AuthModal from './authAlter';
import { auth } from '../firebase/firebaseAuth';
import "../sidebar.css";
import tlogo from "../assets/resultCropped.png"
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { Image } from 'react-bootstrap';
import CheckoutButton from './checkoutButton';
import SubscriptionModal from './subscriptionModal';
import Button from 'react-bootstrap/Button';
import { MdOutlineRocketLaunch } from "react-icons/md";

const Sidebar = ({subscriptionShow, updateSubscriptionState, modalShow, updateModalState, isLogged, user, userAvatar, updateAuthState,  resetCredits, uid, isSubscribed, handleAnonymousSignIn}) => {
    const location = useLocation();
  const [isExpanded, setExpanded] = useState(true);
  const direction = "up-centered";
  const navigate = useNavigate();
  const UserMenuExpanded = (
    <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', width: '95px'}}>
    {userAvatar !== null ? <Image
      src={userAvatar}
      referrerPolicy="no-referrer"
      roundedCircle

      style={{ width: '30px', paddingRight: '3px'}}
    /> :  <MdAccountCircle size={24} />}

    <span style={{fontSize: '1.2rem'}}>{' '} {user}</span></div>
  )
  const UserMenu = (
    <div style={{ width: '18px', alignSelf: 'center'}}>
      {userAvatar !== null ? <Image
      src={userAvatar}
      referrerPolicy="no-referrer"
      roundedCircle

      style={{ width: '30px', paddingRight: '3px'}}
    /> :  <MdAccountCircle size={24} />}
    </div>
  )

const handleLogout = async () => {
    try {
      if (auth.currentUser) {
        if (auth.currentUser.email !== "anonymous"){
            await auth.signOut();
            localStorage.removeItem('authToken');
            resetCredits(0);
            await handleAnonymousSignIn();
            navigate('/');
        }

      } else {
        console.log('No user is currently signed in');
      }
    } catch (error) {
      console.error('Error signing out:', error.message);
    }
  };


  const toggleSidebar = () => {
    setExpanded(!isExpanded);
  };

  return (
    <div className={`sidebar ${isExpanded ? 'expanded' : ''}`}>

      <div className='logo'>< img src={tlogo} alt="Logo" width={60} height={60} />
      {isExpanded &&  <span>thinkercan</span>}
        </div>
      <div className="menu">


      <Link to="/?reset=true">
        <div className='menuItemText' style={{width: isExpanded && "10rem"}}><GoPlusCircle size={28}/>
          {isExpanded && <span style={{paddingLeft:"0.3rem"}}>Ask New</span>}
        </div>

        </Link>
        <Link to="/history" className={location.pathname === '/history' ? 'active' : ''}>
        <div className='menuItemText' style={{width: isExpanded && "10rem"}}><MdHistory size={28}/>
          {isExpanded && <span style={{paddingLeft:"0.3rem"}}>History</span>}
          </div>
        </Link>
        {isLogged && !isSubscribed &&  <Link onClick={() =>updateSubscriptionState(true)}>
            <div className='menuItemText' style={{width: isExpanded && "10rem"}}><MdOutlineRocketLaunch size={28}/>
          {isExpanded && <span style={{paddingLeft:"0.3rem"}}>Try Pro</span>}
          </div> </Link>}
        {!isLogged &&  <Link onClick={() =>updateModalState(true)}>
            <div className='menuItemText' style={{width: isExpanded && "10rem"}}><MdLogin size={28}/>
          {isExpanded && <span style={{paddingLeft:"0.3rem"}}>Sign In</span>}
          </div> </Link>}


        <AuthModal show={modalShow} handleClose={() => updateModalState(false)} updateAuthState={updateAuthState} handleAnonymousSignIn={handleAnonymousSignIn} uid={uid}/>


      </div>
      <div className="toogle-container"><button className="toggle-button" style={{borderRadius:'5rem'}} onClick={toggleSidebar}>
        {isExpanded ? <MdKeyboardDoubleArrowLeft size={24} /> : <MdKeyboardDoubleArrowRight size={24}/>}
      </button></div>




      <SubscriptionModal show={subscriptionShow} handleClose={() => updateSubscriptionState(false)} uid={uid}/>


      <div className="bottom-menu">
        {isLogged &&    <div className="mb-2">
      <SplitButton className='dropdown-button-user'
              as={ButtonGroup}
              key={direction}
              id={`dropdown-button-drop-${direction}`}
              drop={direction}
              variant="secondary"
              size='lg'

              title={isExpanded ? UserMenuExpanded: UserMenu}
            >
              <Dropdown.Item eventKey="1" as={Link} to='/account'>Profile</Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item eventKey="2" onClick={() =>handleLogout()}>Log out</Dropdown.Item>
            </SplitButton>
      </div>}

      </div>
    </div>
  );
};

export default Sidebar;









