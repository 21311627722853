import "../BottomBar.css"
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { MdQuestionAnswer, MdHistory, MdLogin, MdAccountCircle, MdOutlineRocketLaunch, MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight } from 'react-icons/md'; // Importing icons
import AuthModal from './authAlter';
import { Container, Form, Button, Row, Col, Image } from 'react-bootstrap';
import SubscriptionModal from './subscriptionModal';
import { GoPlusCircle } from "react-icons/go";


const BottomBar = ({subscriptionShow, updateSubscriptionState, uid, isSubscribed, modalShow, updateModalState, isLogged, user, userAvatar}) => {
    const location = useLocation();

    return (
      <nav className="bottom-navbar">

        <Container>
        <AuthModal show={modalShow} handleClose={() => updateModalState(false)} uid={uid}/>
        <SubscriptionModal show={subscriptionShow} handleClose={() => updateSubscriptionState(false)} uid={uid}/>

          <Row >

            <Col className="text-center">
            <Link to="/?reset=true">
              <GoPlusCircle size={28}/>

              </Link>
            </Col>
            <Col className="text-center">
            <Link to="/history" className={location.pathname === '/history' ? 'active' : ''}>
                <MdHistory size={28}/></Link>
            </Col>
            {isLogged && !isSubscribed && <Col className="text-center">
            <Link onClick={() =>updateSubscriptionState(true)}>
            <MdOutlineRocketLaunch size={28}/>
            </Link>
            </Col>

            }
            <Col className="text-center">
            {!isLogged ?  <Link onClick={() =>updateModalState(true)}>
            <MdLogin size={28}/>
         </Link> : <Link to="/account" className={location.pathname === '/account' ? 'active' : ''}>
         {userAvatar !== null ? <Image
            src={userAvatar}
            referrerPolicy="no-referrer"
            roundedCircle

      style={{ width: '30px'}}
    /> :  <MdAccountCircle size={32} />} </Link>}
            </Col>


          </Row>

        </Container>






      </nav>
    );
  };

  export default BottomBar;