import React from "react";
import AuthModal from "./authAlter";

const SignUpBanner = ({modalShow, updateModalState, updateAuthState, userUID, handleAnonymousSignIn}) => {
    const handleSignUp = () =>{
        updateModalState(true);
        return <AuthModal show={modalShow} handleClose={() => updateModalState(false)} updateAuthState={updateAuthState} uid={userUID} handleAnonymousSignIn={handleAnonymousSignIn}/>
    ;
    }
  return (
    <div className="signup-banner">
      <p>

        <button className="signup-link" onClick={handleSignUp}> Sign Up</button>
        and get your 5 Free Daily Credits!
      </p>
    </div>
  );
};

export default SignUpBanner;
