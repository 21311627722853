/*useEffect(() => {

      if(userUID){
          const docRef = doc(db, 'users', userUID)

          const fetchData = async () => {

                const data = await getDoc(docRef);
                if(data.data()){
               /*  data.data().chats.map((doca) => {
                  // doc.data() is never undefined for query doc snapshots
                }); */
                //const snapshot = await collection(db, 'users', userUID, 'chats').get();
                /*Object.keys(data.data().chats).forEach(function(key) {
                  setQuestAnswerPairs(h => [...h, data.data().chats[key][0]]);
              });
                //const data = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                //setData(data);

          }};

            fetchData();
      }
  }, [userUID]);*/

  // History.js

import jQuery from 'jquery';
import React, { useState, useEffect } from 'react';
import { ref, once, onValue, push, update } from "firebase/database";
import { db } from '../firebase/realtimeDatabase';
import GraphParser from '../components/createGraphPart';

import 'katex/dist/katex.min.css';
import Latex from 'react-latex-next';
import XMLParser from '../createApiPart';
import { Spinner } from 'react-bootstrap';
import LatexRenderer from '../components/latexRenderer';
function History({userUID}) {
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    // Fetch questions from Firebase Realtime Database
    const fetchQuestions = async () => {
      const starCountRef = ref(db, `/users/${userUID}/chats`);

      onValue(starCountRef, (snapshot) => {
        if (snapshot.exists()){

          const data = snapshot.val();
          if (data){
            const newData = Object.values(data);
            setQuestions([...newData]);
            setLoading(false);

        }
        }
        else{
          setLoading(false);
        }


      });

    };

    fetchQuestions();
  }, [userUID]);



  return (
    <div className="main">
        {loading ? (
            <div className="spinnerHeader"  style={{alignSelf:"center", fontSize:"1.7rem"}}>
                <Spinner animation="border" variant="primary" />{'   '} initalizing
            </div>
        ) : (
            <div className="chats">
                {questions.map((qa, id) => (
                    <div key={id}>
                        <div className="chat">
                            <div className="quest">
                                {qa.quest.hasOwnProperty('quest_image') ? (
                                    <img
                                        src={qa.quest.quest_image}
                                        style={{ width: '100%' }}
                                        alt="Question"
                                    />
                                ) : (
                                    <Latex>{qa.quest.quest_text.replace(/\\\\/g, "\\")}</Latex>
                                )}
                            </div>
                            <div className="answer">
                            {qa.answer.map(a => (
                  <div key={a.id}>
                    {a.type === "api" ? (
                      <XMLParser xmlList={a.value} isFinished={a.isFinished} isFailed={a.isFailed} />
                    ) : a.type === "code" ? (
                      <GraphParser graphPng={a.value} isFinished={a.isFinished} isFailed={a.isFailed}/>
                    ) : (
                      <LatexRenderer content={a.value} />
                    )}
                  </div>
            ))}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        )}
    </div>
);
};

export default History;